<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row align="center" justify="center" v-if="missing_only">
          <v-col cols="12">
            <v-card>
              <v-card-title>
                {{ title }}
              </v-card-title>
              <v-card-text> {{ message }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="schema">
          <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="(item, i) in sub_sections"
            :key="i"
          >
            <AutoSubSection
              ref="auto"
              :sub_section="item"
              :model_input="get_data_sub_section(item)"
              :hide_save="true"
              @profile_updated="profile_updated"
              @valid="valid_sec"
            ></AutoSubSection>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="blue darken-1"
        text
        @click="on_persist"
        :disabled="!all_valid"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vuex from "vuex";

import AutoSubSection from "@/components/profile/AutoSubSection.vue";

export default {
  name: "Profile",
  components: { AutoSubSection },
  props: ["missing_only"],
  data: () => ({
    title: "Required information or Required actions",
    message:
      "Some data is required to fulfill your profile and start to operate the system. Please set all required data.",
    profile: null,
    schema: null,
    errors: null,
    valid: {},
    realc_validate: 1,
    delay_validate: null,
    debug: false,
  }),
  methods: {
    async fetch_schema_profiles(sub_section) {
      let params = { sub_section: sub_section };
      var path = this.T.build_path_data(
        this.apip.API_SCHEMA_PROFILES_PATH,
        params
      );
      try {
        var response = await this.pyx_api.get(path);
        return response.data;
      } catch (error) {
        this.show_error_message(error);
        return null;
      }
    },
    async fetch_profile(sub_section) {
      let params = { sub_section: sub_section };
      var path = this.T.build_path_data(
        this.apip.API_PROFILES_PATH + "/mine",
        params
      );
      try {
        var response = await this.pyx_api.get(path);
        return response.data;
      } catch (error) {
        this.show_error_message(error);
        return null;
      }
    },
    get_data_sub_section(sub_section) {
      if (this.profile) {
        if (this.debug)
          console.log(
            "get_data_sub_section",
            sub_section,
            this.profile[sub_section]
          );
        return this.profile[sub_section];
      }
      return null;
    },
    async profile_updated(sub_section, model) {
      this.profile[sub_section] = model;
      if (this.delay_validate) {
        clearTimeout(this.delay_validate);
      }
      setTimeout(() => {
        this.validate();
      }, 1000);
    },
    validate() {
      let res = this.T.validate_schema(this.schema, this.profile);
      if (this.debug) console.log(res);
      if (!res[0]) this.errors = res[1].errors;
      else this.errors = null;

      if (this.missing_only) {
        this.set_requires_profile_data(this.errors != null);
      }
    },
    async reload() {
      this.profile = await this.fetch_profile(null);
      this.schema = await this.fetch_schema_profiles(null);
      this.validate();
    },
    valid_sec(sec) {
      this.valid[sec.name] = sec.valid;
      this.realc_validate += 1;
    },
    on_persist() {
      if (this.debug) console.log(this.$refs?.auto);
      this.$refs?.auto?.forEach((comp) => {
        comp.on_persist();
      });
    },
    ...Vuex.mapActions([
      "show_error_message",
      "show_info_message",
      "set_requires_profile_data",
    ]),
  },
  computed: {
    sub_sections() {
      if (this.missing_only) {
        if (this.errors) {
          let properties = this.errors
            .map((e) => {
              try {
                return e.dataPath.split(".")[1];
              } catch (error) {
                return null;
              }
            })
            .filter((prop) => prop != null);
          properties = [...new Set(properties)];
          if (this.debug) console.log(properties);
          return properties;
        }
        return [];
      }
      if (this.schema?.properties) return Object.keys(this.schema?.properties);
      else return [];
    },
    all_valid() {
      let all = true;
      if (this.realc_validate)
        Object.keys(this.valid).forEach((s) => {
          all &= this.valid[s];
        });
      return all;
    },
  },
  async mounted() {
    await this.reload();
  },
};
</script>
