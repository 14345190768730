<template>
  <div>
    <v-menu offset-y rounded="lg" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ma-0 pa-2" v-bind="attrs" v-on="on">
          <v-icon> mdi-account-circle</v-icon>
          <div class="text-none d-none d-sm-block ml-1">
            <div class="text-left">{{ get_user_title }}</div>
            <div class="text-left caption">Click here to se options</div>
          </div>
          <v-icon>{{ btn_action }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-content class="text-center">
            <div class="d-block font-weight-light">Account:</div>
            <div class="">{{ user_data?.user?.email }}</div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item :to="{ name: 'profiles' }">
          <v-list-item-avatar> <v-icon>mdi-cog</v-icon></v-list-item-avatar>
          <v-list-item-content> Account Settings</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content> <login-user></login-user> </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import Vuex from "vuex";
import LoginUser from "../LoginUser.vue";

export default {
  components: { LoginUser },
  data() {
    return {
      menu: null,
      debug: false,
    };
  },
  computed: {
    get_user_title() {
      if (this.user_profile?.base_information) {
        let first_name = this.user_profile.base_information.first_name;
        let last_name = this.user_profile.base_information.last_name;
        return `${first_name ? first_name : ""} ${last_name ? last_name : ""}`;
      }
    },
    btn_action() {
      if (this.menu) return "mdi-chevron-up";
      else return "mdi-chevron-down";
    },
    ...Vuex.mapState(["is_ready", "user_data", "user_profile"]),
  },
};
</script>
