export default {
  data() {
    return {
      rules: {
        touch: (value) => !value || !!value || "Required.",
        required: (value) => !!value || "Required.",
        one_in_array: (value) => value?.length > 0 || "Required.",
        min: (v) => (v || "").length >= 8 || "Must be more than 8 characters",
        is_email: (v) => /.+@.+/.test(v) || "E-mail must be valid",
        no_spaces(v) {
          if (v && typeof v !== "string") return true;
          return (v || "").indexOf(" ") < 0 || "No spaces are allowed";
        },
        required_number: (v) => !isNaN(parseFloat(v)) || "Required number.",
        is_positive_number: (v) =>
          v == "" ||
          v == null ||
          (!isNaN(parseFloat(v)) && v >= 0) ||
          "Positive number.",
        required_positive: (v) =>
          (!isNaN(parseFloat(v)) && v >= 0) || "Required postive number.",
        has_two_decimal_digits: (v) => {
          v = (v || "").toString();
          let i = v.split(".");
          if (i.length == 1) return true;
          if (i.length == 2 && i[1].split("").length <= 2) return true;
          return "Only two decimal digits.";
        },
      },
    };
  },
};
