import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    requires_profile_data: null,
    is_login: null,
    user_data: null,
    user_profile: null,
    main_current: "new-user",
    system_error: null,
    system_info: null,
    is_ready: false,
  },
  // getters: {},
  mutations: {
    user_login(state, user_data) {
      state.is_login = true;
      state.user_data = user_data;
    },
    user_logout(state) {
      state.is_login = false;
      state.user_data = null;
    },
    m_set_main_current(state, current) {
      state.main_current = current;
    },
    m_show_error_message(state, error) {
      state.system_error = error;
    },
    m_show_info_message(state, info) {
      state.system_info = info;
    },
    m_requires_profile_data(state, val) {
      state.requires_profile_data = val;
      // This is last step on login
      state.is_ready = true;
    },
    m_set_ready(state, val) {
      state.is_ready = val;
    },
    m_set_user_profile(state, user_profile) {
      state.user_profile = user_profile;
    },
  },
  actions: {
    system_login({ commit }, user_data) {
      commit("user_login", user_data);
    },
    system_logout({ commit }) {
      commit("user_logout");
    },
    set_main_current({ commit }, current) {
      commit("m_set_main_current", current);
    },
    set_requires_profile_data({ commit }, val) {
      commit("m_requires_profile_data", val);
    },
    set_ready({ commit }, val) {
      commit("m_set_ready", val);
    },
    set_user_profile({ commit }, user_profile) {
      commit("m_set_user_profile", user_profile);
    },
    show_info_message({ commit }, info) {
      commit("m_show_info_message", info);
    },
    show_error_message({ commit }, error) {
      commit("m_show_error_message", error);
    },
  },
  modules: {},
});
