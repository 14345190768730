<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1>Dashboard</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs v-model="tab">
        <v-tab class="text-none">Main</v-tab>
        <v-tab class="text-none">Beginner's Guide</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item><dash-main></dash-main></v-tab-item>
        <v-tab-item><dash-guide></dash-guide></v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import DashMain from "@/components/dashboard/DashMain.vue";
import DashGuide from "@/components/dashboard/DashGuide.vue";

export default {
  name: "Dashboard",
  components: { DashMain, DashGuide },
  data() {
    return {
      tab: null,
      mock: false,
      debug: false,
    };
  },
  methods: {},
  computed: {
    ...mapState(["is_login", "user_data"]),
  },
  mounted() {},
};
</script>
