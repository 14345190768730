<template>
  <div>
    <v-btn
      v-if="!is_login"
      class="ma-2"
      color="primary"
      @click="login_form = true"
    >
      <v-icon left>mdi-login</v-icon>
      Login
    </v-btn>
    <v-btn v-else class="text-none" color="secondary" @click="do_logout" block>
      <v-icon left>mdi-logout</v-icon>
      Logout
    </v-btn>

    <v-dialog v-model="login_form" persistent max-width="350">
      <v-card class="py-2 px-8 my-1">
        <v-alert
          v-model="error_alert"
          dense
          dismissible
          outlined
          prominent
          text
          type="error"
          transition="scale-transition"
        >
          {{ error_message }}</v-alert
        >

        <v-card-title class="text-h5"> User Login </v-card-title>
        <v-divider></v-divider>
        <v-form ref="loginuser" v-model="valid">
          <div class="my-6">
            <v-text-field
              v-model="username"
              label="Username"
              :rules="[rules.required, rules.no_spaces]"
              required
              dense
              outlined
            >
            </v-text-field>
            <v-text-field
              v-model="pwd"
              label="Password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.no_spaces]"
              :type="show1 ? 'text' : 'password'"
              required
              filled
              @click:append="show1 = !show1"
              @keyup.enter="valid ? do_login() : false"
            >
            </v-text-field>
          </div>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="login_form = false"> Close </v-btn>
          <v-btn :disabled="!valid" @click="do_login"> Login </v-btn>
        </v-card-actions>
        <v-card-actions>
          <router-link
            :to="{ name: 'account_recovery' }"
            @click.native="login_form = false"
            >Forgot password or username?</router-link
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "LoginUser",
  data() {
    return {
      username: null,
      pwd: null,
      login_form: false,
      valid: false,
      show1: false,
      error_alert: false,
      error_message: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v || "").length >= 8 || "Must be more than 8 characters",
        no_spaces: (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed",
        same_value: (v) => this.new_user.pwd === v || "Not the same password.",
        is_email: (v) => /.+@.+/.test(v) || "E-mail must be valid",
      },
      debug: false,
    };
  },
  computed: {
    ...Vuex.mapState(["is_login"]),
  },
  methods: {
    async do_login() {
      var token;
      const params = new URLSearchParams();
      params.append("grant_type", "password");
      params.append("username", this.username);
      params.append("password", this.pwd);
      const config = {
        headers: {
          "X-Org-Name": "Proteryx",
        },
      };
      try {
        var response = await this.pyx_api.post(
          this.apip.API_TOKEN_PATH,
          params
        );
        token = response.data.access_token;
      } catch (error) {
        console.log(error);
        this.error_alert = true;
        this.error_message = error.response.data.detail;
        return;
      }
      this.set_login(token);
    },
    async request_user_data() {
      var path = `${this.apip.API_USER_LIST_PATH}/whoami`;
      try {
        var response = await this.pyx_api.get(path);
        return {
          user: response.data,
        };
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async set_login(token) {
      this.login_form = false;
      if (this.$refs.loginuser) this.$refs.loginuser.reset();
      localStorage.setItem("login_token", token);
      this.pyx_api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let user_data = await this.request_user_data();
      if (user_data) {
        user_data.token = token;
        this.system_login(user_data);
      } else this.do_logout();
    },
    do_logout() {
      this.set_ready(false);
      this.system_logout();
      localStorage.removeItem("login_token");
      this.pyx_api.defaults.headers.common["Authorization"] = `Bearer BAD`;
      // convinient to reload page for clear storage vars.
      window.location = process.env.VUE_APP_PUBLIC_PATH;
    },
    ...Vuex.mapActions(["system_login", "system_logout", "set_ready"]),
  },
  mounted() {
    const token = localStorage.getItem("login_token");
    if (token && this.is_login == null) {
      if (this.debug) console.log("token exist");
      this.set_login(token);
    } else if (!token && this.is_login == null) {
      this.system_logout();
      this.set_ready(true);
    }
  },
};
</script>
