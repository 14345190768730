<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card width="500" class="py-2 px-8 my-4">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text><NewUser></NewUser></v-card-text>
        <v-card-actions>
          <v-btn class="text-none" text x-small :to="{ name: 'login' }"
            >Login</v-btn
          ><v-spacer></v-spacer>
          <v-btn
            class="text-none"
            text
            x-small
            :to="{ name: 'account_recovery' }"
            >forgot password or username?</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Vuex from "vuex";
import NewUser from "@/components/home/NewUser.vue";

export default {
  name: "OfflineHome",
  components: { NewUser },
  data() {
    return {
      title: "Welcome to Proteryx!",
      message: "Start using our service.",
    };
  },
};
</script>
