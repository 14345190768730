<template>
  <v-card width="100%" flat>
    <v-card-title class="d-flex">
      <span class="d-flex justify-start">Learn Proteryx in 2 minutes</span>
    </v-card-title>
    <v-card-text>
      <v-carousel v-model="vid_model">
        <v-carousel-item v-for="(v, i) in videos" :key="i">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <iframe
                :width="v.width"
                :height="v.height"
                :src="v.src"
                :title="v.title"
                :frameborder="v.frameborder"
                :allow="v.allow"
                :referrerpolicy="v.referrerpolicy"
                allowfullscreen
              ></iframe>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
    <v-card-text>
      <p class="text-justify">
        Proteryx is the system designed to automate your trading strategy by
        converting your trade signals into live orders on your chosen broker.
        Once operational, you'll find minimal need to interact with the
        platform, allowing you to focus on your trading goals, refine your
        strategy, and develop your expertise while Proteryx handles the
        execution.
      </p>

      <p>Let's get you started.</p>
    </v-card-text>
    <v-card-title>Discord</v-card-title>
    <v-card-subtitle>Join Discord Community</v-card-subtitle>
    <v-card-text>
      <p class="text-justify">
        Expand your knowledge and trading network. Join the Proteryx Discord
        community to connect with experienced automated traders. Gain valuable
        insights, share strategies with like-minded individuals, and access
        exclusive resources to enhance your algorithmic trading development.
      </p>
      <v-btn text class="text-none">
        <a href="https://discord.gg/ZQ7vn6WrD3" target="_blank">Join Discord</a>
      </v-btn>
    </v-card-text>

    <v-card-title>Documentation</v-card-title>
    <v-card-subtitle>Refer to the Documentation</v-card-subtitle>
    <v-card-text>
      <p class="text-justify">
        Deepen your understanding of Proteryx by exploring our comprehensive
        documentation. This valuable resource offers a wealth of information and
        detailed insights to help you optimize your automated trading strategies
        with Proteryx.
      </p>
      <v-btn text class="text-none">
        <a
          href="https://proteryx.simplebase.co/en/category/proteryx"
          target="_blank"
          >Read Documentation</a
        >
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DashGuide",
  components: {},
  data() {
    return {
      vid_model: null,
      videos: [
        {
          width: "665",
          height: "374",
          src: "https://www.youtube.com/embed/XHOmBV4js_E",
          title: "Video Placeholder",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
          referrerpolicy: "strict-origin-when-cross-origin",
        },
        {
          width: "665",
          height: "374",
          src: "https://www.youtube.com/embed/XHOmBV4js_E",
          title: "Video Placeholder",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
          referrerpolicy: "strict-origin-when-cross-origin",
        },
        {
          width: "665",
          height: "374",
          src: "https://www.youtube.com/embed/XHOmBV4js_E",
          title: "Video Placeholder",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
          referrerpolicy: "strict-origin-when-cross-origin",
        },
      ],
      mock: false,
      debug: false,
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
