<template>
  <div id="app">
    <v-app>
      <v-app-bar app dense rounded v-if="is_login">
        <v-app-bar-nav-icon
          v-if="is_login"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-app-bar-title>Proteryx App</v-app-bar-title>
        <div id="tp-app-bar-start"></div>
        <v-spacer></v-spacer>
        <div id="tp-app-bar-middle"></div>
        <v-spacer></v-spacer>
        <div id="tp-app-bar-last" class="mr-1"></div>
        <drop-down-profile-menu></drop-down-profile-menu>
      </v-app-bar>
      <LoginUser v-show="false" v-else></LoginUser>

      <v-navigation-drawer app v-if="is_login" v-model="drawer" temporary>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>mdi-application-brackets</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Proteryx App</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense v-if="!requires_profile_data">
          <v-list-item v-for="item in menues" :key="item.title" :to="item.link">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense v-if="!requires_profile_data">
          <v-list-item>TRADING</v-list-item>
          <v-list-item
            v-for="item in trading_menues"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.title_right"
              ><span class="text-caption primary--text text--lighten-1">{{
                item.title_right
              }}</span></v-list-item-action
            >
          </v-list-item>
          <v-list-item>OTHER</v-list-item>
          <v-list-item
            href="https://proteryx.simplebase.co/en/category/proteryx"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Documentation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in other_menues"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.title_right"
              ><span class="text-caption primary--text text--lighten-1">{{
                item.title_right
              }}</span></v-list-item-action
            >
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <LoadOverlay :activate="!is_ready"></LoadOverlay>

        <router-view></router-view>
      </v-main>

      <v-footer app>
        <!-- <v-container> </v-container> -->
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import Vuex from "vuex";

import LoadOverlay from "@/components/helpers/LoadOverlay.vue";
import LoginUser from "@/components/LoginUser.vue";
import DropDownProfileMenu from "@/components/profile/DropDownProfileMenu.vue";

export default {
  name: "App",
  components: {
    LoginUser,
    LoadOverlay,
    DropDownProfileMenu,
  },
  data: () => ({
    drawer: false,
    profile: null,
    schema_profile: null,
    errors: null,
    menues: [
      { title: "Dashboard", icon: "mdi-view-dashboard", link: "/" },
      // {
      //   title: "Profile",
      //   icon: "mdi-account-box-multiple",
      //   link: "/profiles",
      //   any_permission: ["user:read"],
      // },
      {
        title: "Accounts and Billing",
        icon: "mdi-shopping",
        link: "/accounts-and-billing",
        any_permission: ["user:read"],
      },
      // {
      //   title: "Signals",
      //   icon: "mdi-message-fast",
      //   link: "/stack-signals",
      //   any_permission: ["user:read"],
      // },
      // {
      //   title: "Brokers",
      //   icon: "mdi-server",
      //   link: "/node-brokers",
      //   any_permission: ["user:read"],
      // },
      // {
      //   title: "Linking",
      //   icon: "mdi-network",
      //   link: "/notify-linking",
      //   any_permission: ["user:read"],
      // },
      {
        title: "Brokers",
        icon: "mdi-network",
        link: "/linking",
        any_permission: ["user:read"],
      },
      // { title: "Configs", icon: "mdi-cog", link: "/configs" },
      // { title: "Memberships", icon: "mdi-cog", link: "/memberships" },
      // { title: "About", icon: "mdi-information", link: "/about" },
    ],
    trading_menues: [
      {
        title: "Signals Pro",
        title_right: "BETA",
        icon: "mdi-broadcast",
        link: "/signals-pro",
        any_permission: ["user:read"],
      },
      {
        title: "Terminal",
        title_right: null,
        icon: "mdi-console",
        link: "/terminal",
        any_permission: ["user:read"],
      },
      {
        title: "Marketplace",
        title_right: null,
        icon: "mdi-shopping-outline",
        link: "/marketplace",
        any_permission: ["user:read"],
      },
    ],
    other_menues: [
      {
        title: "FAQ",
        title_right: null,
        icon: "mdi-frequently-asked-questions",
        link: "/faq",
        any_permission: ["user:read"],
      },
    ],
    debug: false,
  }),
  methods: {
    async fetch_schema_profiles(sub_section) {
      let params = { sub_section: sub_section };
      var path = this.T.build_path_data(
        this.apip.API_SCHEMA_PROFILES_PATH,
        params
      );
      try {
        var response = await this.pyx_api.get(path);
        return response.data;
      } catch (error) {
        this.show_error_message(error);
        return null;
      }
    },
    async fetch_profile(sub_section) {
      let params = { sub_section: sub_section };
      var path = this.T.build_path_data(
        this.apip.API_PROFILES_PATH + "/mine",
        params
      );
      try {
        var response = await this.pyx_api.get(path);
        return response.data;
      } catch (error) {
        this.show_error_message(error);
        return null;
      }
    },
    async get_profile_data() {
      this.profile = await this.fetch_profile(null);
      if (this.debug) console.log(this.profile);
      this.set_user_profile(this.profile);
      this.schema_profile = await this.fetch_schema_profiles(null);
    },
    async check_for_profile_data() {
      let res = this.T.validate_schema(this.schema_profile, this.profile);
      if (!res[0]) this.errors = res[1].errors;
      else this.errors = null;
      this.set_requires_profile_data(this.errors != null);
      if (this.errors != null) {
        if (this.$route.path != "/") this.$router.push("/");
      }
    },
    ...Vuex.mapActions([
      "set_requires_profile_data",
      "system_login",
      "set_user_profile",
    ]),
  },
  watch: {
    async is_login(nval) {
      if (nval) {
        await this.get_profile_data();
        await this.check_for_profile_data();
      } else {
        this.set_requires_profile_data(false);
      }
    },
  },
  computed: {
    ...Vuex.mapState(["is_login", "requires_profile_data", "is_ready"]),
  },
  mounted() {},
};
</script>
<style></style>
