export default {
  short_list(l, max = -1, sep = ", ", elipsis = "...") {
    let man;
    if (max > 0) {
      let top = max <= l.length ? max : l.length;
      man = l.slice(0, top);
    }
    if (elipsis && l.length > max) {
      man.push(elipsis);
    }
    return man.join(sep);
  },
  toPascalCase(string) {
    return `${string}`
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, "g"), " ")
      .replace(new RegExp(/[^\w\s]/, "g"), "")
      .replace(
        new RegExp(/\s+(.)(\w*)/, "g"),
        ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  },
  shortNumber(str_number) {
    try {
      let num = parseInt(str_number);
      if (num < 1000) return str_number;
      let res = num / 1000;

      return `${res} k`;
    } catch (error) {
      return str_number;
    }
  },
  numberWithComma(str_number) {
    let s1 = str_number.split(".");
    let r = s1[0].split("").reverse();
    let buffer = [];
    if (r.length > 0) buffer.push(r[0]);
    for (let i = 1; i < r.length; i++) {
      if (i % 3 == 0 && i != r.length) {
        buffer.push(",");
      }
      buffer.push(r[i]);
    }
    s1[0] = buffer.reverse().join("");
    return `${s1.join(".")}`;
  },
  numberWithDecimals(number, decimals = 2) {
    return number.toFixed(decimals).toString();
  },
  showNumberNZ(val) {
    return val == null || val == 0 || val == 1.7976931348623157e308
      ? "\u00B7 \u00B7 \u00B7"
      : val;
  },
  getSTRPrice(price) {
    return price
      ? `${price.currency} ${this.showAsMoney(price.unit_amount / 100)}`
      : "--";
  },
  showAsMoney(number, positive = true) {
    return `${positive ? "" : "-"}$ ${this.numberWithComma(
      this.numberWithDecimals(number)
    )}`;
  },
  show_as_money_view(value) {
    if (value) return this.showAsMoney(Math.abs(value), value >= 0);
    else return "--";
  },
  shortener(s, i = 0, e = -1) {
    if (!s) return "-";
    if (e > 0) return `${s.substring(i, e)}...`;
    return s;
  },
  zeroPad(num, places) {
    return String(num).padStart(places, "0");
  },
  timeFormat(time) {
    return `${this.zeroPad(time.hours, 2)}:${this.zeroPad(
      time.minutes,
      2
    )}:${this.zeroPad(time.seconds, 2)}`;
  },
  copyToClipboard(text) {
    navigator.clipboard.writeText(text);
  },
  to_locale(date) {
    if (date) return date.toLocaleString();
    else return "--";
  },
  get_date_locale(datetime_str) {
    if (datetime_str) {
      try {
        return new Date(datetime_str).toLocaleString();
      } catch (error) {
        console.log(error);
        return "---";
      }
    }
    return "--";
  },
  get_on_off(value) {
    if (value == null || value == undefined) return "";
    return value ? "On" : "Off";
  },
  mask_webhook_uuid(guid, asterisks = 8) {
    if (!guid) return;
    let start = 6;
    let end = 30;
    let a = guid.substring(0, start);
    let b = `-${"*".repeat(asterisks)}-`;
    let c = guid.substring(end);
    return a + b + c;
  },
};
