import Ajv from "ajv";

export default {
  mask_guid(guid) {
    if (!guid) return;
    let start = 5;
    let end = 30;
    let a = guid.substring(0, start);
    let b = guid.substring(start, end).replace(/[^-]/gi, "∗");
    let c = guid.substring(end);
    return a + b + c;
  },
  guid() {
    function _p8(s) {
      var p = (Math.random().toString(16) + "000000000").substring(2, 10);
      return s ? "-" + p.substring(0, 4) + "-" + p.substring(4, 8) : p;
    }
    return _p8() + _p8(true) + _p8(true) + _p8();
  },
  copy(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  millis_to_time(m) {
    let millis = m % 1000;
    let seconds = Math.floor(m / 1000);
    let hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    return { hours: hours, minutes: minutes, seconds: seconds, millis: millis };
  },
  build_path_data(url, params) {
    const fetch_url = new URL("http://test" + url);
    Object.keys(params).forEach((k) => {
      if (k in params && !(params[k] === "" || params[k] === null)) {
        fetch_url.searchParams.append(k, params[k]);
      }
    });
    let path = fetch_url.pathname + fetch_url.search + fetch_url.hash;
    return path;
  },
  build_as_query_path_data(params) {
    const fetch_url = new URL("http://test");
    Object.keys(params).forEach((k) => {
      if (k in params && !(params[k] === "" || params[k] === null)) {
        fetch_url.searchParams.append(k, params[k]);
      }
    });
    return fetch_url.search;
  },
  validate_schema(schema, data) {
    const ajv = new Ajv({ allErrors: true });
    const validate = ajv.compile(schema);
    const v = validate(data);
    return [v, validate];
  },
  resolve_schema_ref(schema, ref) {
    // console.log(schema);
    if (ref && ref?.startsWith("#/")) {
      let parts = ref.split("/");
      // console.log(parts);
      let obj = schema;
      let i = 1;
      while (obj && i < parts.length) {
        obj = this.copy(obj[parts[i]]);
        // console.log(obj, parts[i], i);
        i++;
      }
      // console.log(obj);
      obj.definitions = this.copy(schema.definitions);
      return obj;
    }
    return null;
  },
  remove_port(url) {
    let U = new URL(url);
    if (U.protocol == "https:") {
      U.port = 443;
      return U.toString();
    } else if (U.protocol == "http:") {
      U.port = 80;
      return U.toString();
    }
    return url;
  },
  diff_array(arr1, arr2) {
    let difference = arr1
      .filter((x) => !arr2.includes(x))
      .concat(arr2.filter((x) => !arr1.includes(x)));
    // symmertic difference
    return difference;
  },
  sort_by_preorder(arr, preorder) {
    arr.sort(function (a, b) {
      return preorder.indexOf(a) - preorder.indexOf(b);
    });
  },
};
