import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/register/:service?",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/RegisterView.vue"),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/login/:service?",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/ib-instances",
    name: "ib-instances",
    component: () =>
      import(
        /* webpackChunkName: "ib-instances" */ "../views/IBInstancesView.vue"
      ),
  },
  {
    path: "/stack-signals",
    name: "stack-signals",
    component: () =>
      import(
        /* webpackChunkName: "stack-signals" */ "../views/StackSignalsView.vue"
      ),
  },
  {
    path: "/node-brokers",
    name: "node-brokers",
    component: () =>
      import(
        /* webpackChunkName: "node-brokers" */ "../views/NodeBrokersView.vue"
      ),
  },
  {
    path: "/notify-linking",
    name: "notify-linking",
    component: () =>
      import(
        /* webpackChunkName: "notify-linking" */ "../views/NotifyLinkingView.vue"
      ),
  },
  {
    path: "/linking",
    name: "linking",
    component: () =>
      import(/* webpackChunkName: "linking" */ "../views/AllLinkingView.vue"),
  },
  {
    path: "/signal-history",
    name: "signal-history",
    component: () =>
      import(
        /* webpackChunkName: "signal-history" */ "../views/SignalHistoryView.vue"
      ),
  },
  {
    path: "/accounts-and-billing",
    name: "billing",
    component: () =>
      import(
        /* webpackChunkName: "billing" */ "../views/AccountsAndBillingView.vue"
      ),
  },
  {
    path: "/configs",
    name: "configs",
    component: () =>
      import(/* webpackChunkName: "configs" */ "../views/ConfigsView.vue"),
  },
  {
    path: "/profiles",
    name: "profiles",
    component: () =>
      import(/* webpackChunkName: "profiles" */ "../views/ProfilesView.vue"),
  },
  {
    path: "/memberships",
    name: "memberships",
    component: () =>
      import(
        /* webpackChunkName: "memberships" */ "../views/MembershipsView.vue"
      ),
  },
  {
    path: "/account-recovery",
    name: "account_recovery",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "../views/AccountRecoveryView.vue"
      ),
  },
  {
    path: "/signals-pro",
    name: "signalspro",
    component: () =>
      import(
        /* webpackChunkName: "signalspro" */ "../views/SignalsProView.vue"
      ),
  },
  {
    path: "/terminal",
    name: "terminal",
    component: () =>
      import(/* webpackChunkName: "terminal" */ "../views/TerminalView.vue"),
  },
  {
    path: "/marketplace",
    name: "marketplace",
    component: () =>
      import(
        /* webpackChunkName: "marketplace" */ "../views/MarketplaceView.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/FAQView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to?.meta?.title ? to.meta.title : "Proteryx App";
  });
});

export default router;
