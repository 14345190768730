<template>
  <v-container>
    <v-row>
      <div v-if="affiliate" class="mb-4">
        You have been referred by {{ affiliate.name }}
      </div>
      <div v-else class="mb-4">Create Your Account</div>
    </v-row>
    <v-form ref="create_user" v-model="valid">
      <v-row>
        <v-text-field
          v-model="base_info.first_name"
          label="First name"
          :rules="[rules.required]"
          dense
          outlined
        >
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          v-model="base_info.last_name"
          label="Last name"
          :rules="[rules.required]"
          dense
          outlined
        >
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          v-model="new_user.username"
          label="Username"
          :rules="[rules.required, rules.min, rules.no_spaces]"
          dense
          outlined
        >
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          v-model="new_user.email"
          label="Email"
          :rules="[rules.required, rules.no_spaces, rules.is_email]"
          dense
          outlined
        >
        </v-text-field>
      </v-row>
      <v-row class="mb-1 mt-0">
        <vue-tel-input
          mode="auto"
          class="flex-grow-1 flex-shrink-0"
          :validCharactersOnly="true"
          :inputOptions="{
            required: true,
            placeholder: 'Enter a cellphone number',
            showDialCode: true,
            styleClasses: '',
          }"
          invalidMsg="Invalid"
          v-model="base_info.cell_phone"
          @validate="cell_phone_validate"
        >
          <template v-slot:[`icon-right`]="{}">
            <v-icon
              class="mr-3"
              :color="
                base_info?.cell_phone?.match(lax_regex_phone) ? 'green' : ''
              "
              >mdi-check-circle</v-icon
            ></template
          >
        </vue-tel-input>
      </v-row>
      <v-row>
        <v-text-field
          v-model="new_user.pwd"
          label="Password"
          hint="At least 8 characters"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min, rules.no_spaces]"
          :type="show1 ? 'text' : 'password'"
          counter
          dense
          outlined
          @click:append="show1 = !show1"
        >
        </v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          v-model="new_user.pwd_verif"
          label="Confirm Password"
          hint="Repeat same password."
          dense
          outlined
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.no_spaces, same_value]"
          :type="show2 ? 'text' : 'password'"
          counter
          @click:append="show2 = !show2"
        >
        </v-text-field>
      </v-row>
      <v-row>
        <v-checkbox
          v-model="term_and_conditions_and_privacy_policy"
          color="success"
          ><template v-slot:label>
            <div>
              I agree and accept the
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://proteryx.com/terms-conditions/"
                    @click.stop
                    v-on="on"
                  >
                    Term & Conditions
                  </a>
                </template>
                Opens in new window
              </v-tooltip>
              and the
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://proteryx.com/terms-conditions/#PRIVACY%20POLICY"
                    @click.stop
                    v-on="on"
                  >
                    Privacy Policy
                  </a>
                </template>
                Opens in new window
              </v-tooltip>
            </div>
          </template></v-checkbox
        >
      </v-row>
      <v-row>
        <LoadOverlay :activate="registering" :absolute="true"></LoadOverlay>
        <v-btn
          class="text-none"
          block
          color="accent"
          :disabled="
            !(
              valid &&
              term_and_conditions_and_privacy_policy &&
              base_info?.cell_phone?.match(lax_regex_phone)
            )
          "
          @click="create_user"
        >
          Register
        </v-btn>
      </v-row>
    </v-form>
    <v-row v-if="debug">
      <v-col>
        <p>valid={{ valid }} , {{ base_info }}</p>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="650">
      <v-card>
        <v-card-title class="text-h4 justify-center">
          Confirm your email
        </v-card-title>
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img class="" width="300px" src="@/assets/email-sent.png"></v-img>
        </div>
        <v-card-subtitle class="text-h6 text-center"
          >To continue registration you need to go to the email and follow the
          link</v-card-subtitle
        >
        <v-card-text>
          Be sure to add admin@proteryx.com to your email contact list.<br />
          Doing this protects against overzealous spam filters.<br />
          If you don't receive the email in your inbox, be sure to check your
          spam folder and click the “Not Spam” action.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { VueTelInput } from "vue-tel-input";

import Vuex from "vuex";
import LoadOverlay from "@/components/helpers/LoadOverlay.vue";

import inputRules from "@/mixins/inputRules";

export default {
  mixins: [inputRules],
  components: { LoadOverlay, VueTelInput },
  data() {
    return {
      valid: false,
      dialog: false,
      registering: false,
      new_user: {
        username: "",
        email: "",
        pwd: "",
        pwd_verif: "",
      },
      base_info: { first_name: null, last_name: null, cell_phone: "" },
      cell_phone_data: null,
      cell_phone_formated: null,
      term_and_conditions_and_privacy_policy: null,
      show1: false,
      show2: false,
      referral: null,
      affiliate: null,
      same_value: (v) => this.new_user.pwd === v || "Not the same password.",
      lax_regex_phone: /^\+?[0-9 ()]{10,20}$/,
      debug: false,
    };
  },
  computed: {},
  methods: {
    cell_phone_validate(obj) {
      if (this.debug) console.log(obj);
      this.cell_phone_data = obj;
    },
    create_user: function () {
      var me = this;
      let base = btoa(JSON.stringify(this.base_info));
      const config = {
        headers: {
          "X-Org-Name": "Proteryx",
          "X-Referral": this.referral,
          "X-Base-Info": base,
        },
      };
      this.registering = true;
      this.pyx_api
        .post(
          this.apip.API_NEW_USER_PATH,
          {
            username: this.new_user.username,
            email: this.new_user.email,
            password: this.new_user.pwd,
          },
          config
        )
        .then(function (response) {
          me.registering = false;
          me.create_success();
        })
        .catch(function (error) {
          me.registering = false;
          me.show_error_message(error);
        });
    },
    create_success: function () {
      this.dialog = true;
      this.$refs.create_user.reset();
    },
    set_rewardful() {
      let me = this;
      rewardful("ready", function () {
        if (Rewardful.referral) {
          if (me.debug)
            console.log("Current referral ID: ", Rewardful.referral);
          me.referral = Rewardful.referral;
        } else {
          if (me.debug) console.log("No referral present.");
        }
        if (Rewardful.affiliate) {
          me.affiliate = Rewardful.affiliate;
        }
      });
    },
    ...Vuex.mapActions(["show_error_message", "show_info_message"]),
  },
  mounted() {
    this.set_rewardful();
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
