export default {
  methods: {
    show_string_message(error_str) {
      console.log("message simple:" + error_str);
    },
    show_error: function (error) {
      var error_message = "";
      if (typeof error === "string") {
        error_message = error;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.detail
      ) {
        var detail = error.response.data.detail;
        if (typeof detail === "string" || detail instanceof String) {
          error_message = `Error: ${error.response.data.detail}`;
        } else {
          error_message = `Error: ${error.message}`;
        }
      } else {
        error_message = `Error: ${error.message}`;
      }
      this.show_string_message(error_message);
    },
    show_info: function (info) {
      var info_message = "";
      if (typeof info === "string") {
        info_message = info;
      } else {
        info_message = `${info.message}`;
      }
      this.show_string_message(info_message);
    },
  },
};
