<template>
  <div>
    <v-card v-if="loading" :loading="loading">
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </v-card>
    <v-card v-else>
      <v-card-title @click="log_schema">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form v-if="schema" v-model="valid" ref="form">
          <v-jsf v-model="model" :schema="schema">
            <template slot="custom-phone" slot-scope="context">
              <vue-tel-input
                @input="
                  (p, obj) => {
                    return input_phone(p, obj, context);
                  }
                "
                mode="international"
                class="flex-grow-1 flex-shrink-0"
                :validCharactersOnly="true"
                :inputOptions="{
                  required: true,
                  placeholder: context.label,
                  showDialCode: true,
                  styleClasses: '',
                }"
                v-model="context.value"
                invalidMsg="Invalid"
                @validate="(p) => {}"
              >
                <template v-slot:[`icon-right`]="obj">
                  <v-icon
                    class="mr-3"
                    :color="valid_phones[context.fullKey] ? 'green' : 'red'"
                    >mdi-check-circle</v-icon
                  ></template
                >
              </vue-tel-input>
            </template>
            <template slot="custom-phone-lax" slot-scope="context">
              <vue-tel-input
                @input="
                  (p, obj) => {
                    return input_phone_lax(p, obj, context);
                  }
                "
                :autoFormat="false"
                mode="auto"
                class="flex-grow-1 flex-shrink-0"
                :validCharactersOnly="true"
                :inputOptions="{
                  autocomplete: false,
                  required: true,
                  placeholder: context.label,
                  showDialCode: false,
                  styleClasses: '',
                }"
                v-model="context.value"
                invalidMsg="Invalid"
                @validate="
                  (p) => {
                    return validate_lax(p, context);
                  }
                "
              >
                <template v-slot:[`icon-right`]="obj">
                  <v-icon
                    class="mr-3"
                    :color="valid_phones[context.fullKey] ? 'green' : 'red'"
                    >mdi-check-circle</v-icon
                  ></template
                >
              </vue-tel-input>
            </template>
          </v-jsf>
        </v-form>
        <p v-if="debug">valid={{ valid }} , {{ model }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="on_persist"
          :disabled="!valid"
          v-show="!hide_save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import VJsf from "@koumoul/vjsf/lib/VJsf.js";
import "@koumoul/vjsf/lib/VJsf.css";
import "@koumoul/vjsf/lib/deps/third-party.js";

import { VueTelInput } from "vue-tel-input";
import Vuex from "vuex";

export default {
  name: "AutoSubSection",
  components: { VJsf, VueTelInput },
  props: {
    sub_section: {
      type: String,
      default: null,
    },
    model_input: {
      type: Object,
      default() {
        return null;
      },
    },
    hide_save: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    title: "<None>",
    loading: true,
    model: null,
    schema: null,
    valid: false,
    valid_phones: {},
    lax_regex_phone: /^\+?[0-9 ()]{10,20}$/,
    mock: false,
    debug: false,
  }),
  methods: {
    input_phone(p, obj, context) {
      if (this.debug) console.log("input_phone", p, obj, context);
      if (obj.valid) {
        this.valid_phones[context.fullKey] = true;
        context.on.input(obj.formatted);
      } else {
        this.valid_phones[context.fullKey] = false;
        context.on.input("");
      }
    },
    validate_lax(p, context) {
      if (this.debug) console.log("validate_lax", p, context);
      this.valid_phones[context.fullKey] = context.value.match(
        this.lax_regex_phone
      );
    },
    input_phone_lax(p, obj, context) {
      if (this.debug) console.log(p, obj, context);
      this.valid_phones[context.fullKey] = context.value.match(
        this.lax_regex_phone
      );
      context.on.input(context.value);
    },
    log_schema() {
      console.log(this.schema);
    },
    async fetch_schema_profiles(sub_section) {
      let params = { sub_section: sub_section };
      var path = this.T.build_path_data(
        this.apip.API_SCHEMA_PROFILES_PATH,
        params
      );
      try {
        var response = await this.pyx_api.get(path);
        return response.data;
      } catch (error) {
        this.show_error_message(error);
        return null;
      }
    },
    async fetch_profile(sub_section) {
      let params = { sub_section: sub_section };
      var path = this.T.build_path_data(
        this.apip.API_PROFILES_PATH + "/mine",
        params
      );
      try {
        var response = await this.pyx_api.get(path);
        return response.data;
      } catch (error) {
        this.show_error_message(error);
        return null;
      }
    },
    async save_profile(sub_section, data) {
      let params = { sub_section: sub_section };
      var path = this.T.build_path_data(
        this.apip.API_PROFILES_PATH + "/mine",
        params
      );
      try {
        var response = await this.pyx_api.post(path, data);
        return response.data;
      } catch (error) {
        this.show_error_message(error);
        return null;
      }
    },
    async on_persist() {
      let new_item;
      try {
        new_item = await this.save_profile(this.sub_section, this.model);
      } catch (error) {
        this.show_error_message(error);
        return;
      }
      if (this.post_create_item) this.post_create_item(new_item);
    },
    post_create_item(item) {
      if (item == null) return;
      this.show_info_message({ message: `Profile ${this.title} updated.` });
      this.$emit("profile_updated", this.sub_section, this.model);
    },
    async auto_load() {
      if (this.debug) console.log("auto_load");
      if (this.model_input != null) {
        this.model = this.T.copy(this.model_input);
      } else {
        this.model = null;
      }
      let validate = this.model != null;
      if (this.debug) console.log("model", this.model);
      this.schema = await this.fetch_schema_profiles(this.sub_section);
      this.title = this.schema.title;
      this.loading = false;
      this.$nextTick(() => {
        this.$refs["form"].resetValidation();
        if (validate) {
          this.$refs["form"].validate();
        }
      });
    },
    ...Vuex.mapActions(["show_error_message", "show_info_message"]),
  },
  watch: {
    sub_section(val) {
      if (val) this.auto_load();
    },
    valid(nval) {
      this.$emit("valid", { valid: nval, name: this.sub_section });
    },
  },
  async mounted() {
    await this.auto_load();
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
