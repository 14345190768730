<template>
  <v-overlay
    :absolute="absolute"
    z-index="1000"
    color="white"
    :opacity="opacity"
    :value="activate"
  >
    <v-progress-circular
      indeterminate
      size="64"
      color="blue-grey"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "LoadOverlay",
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    activate: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
};
</script>
