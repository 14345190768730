import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import T from "@/mixins/tools.js";
import ST from "@/mixins/stringTools.js";
// import VueTelInput from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
// Vue.use(VueTelInput);

const PYX_API_PROTOCOL =
  process.env.VUE_APP_API_PROTOCOL == ""
    ? window.location.protocol
    : process.env.VUE_APP_API_PROTOCOL;
const PYX_API_HOSTNAME =
  process.env.VUE_APP_API_HOSTNAME == ""
    ? window.location.hostname
    : process.env.VUE_APP_API_HOSTNAME;
window.location.hostname;
const PYX_API_PORT =
  process.env.VUE_APP_API_PORT == "" ? "8000" : process.env.VUE_APP_API_PORT;
const PYX_API_WS_PROTOCOL =
  process.env.VUE_APP_API_WS_PROTOCOL == ""
    ? "ws"
    : process.env.VUE_APP_API_WS_PROTOCOL;

const PYX_API_HOST =
  PYX_API_HOSTNAME + (PYX_API_PORT === "" ? "" : ":" + PYX_API_PORT);
const PYX_API_SERVER_HTTP = PYX_API_PROTOCOL + "//" + PYX_API_HOST;
// Proteryx URLs
const API_TOKEN_PATH = "/token";
const API_USER_LIST_PATH = "/users";
const API_CHALLENGE_PATH = `${API_USER_LIST_PATH}/challenge-data-checker`;
const API_NEW_USER_PATH = API_USER_LIST_PATH + "/new-user";

const API_IB_MANAGERS_LIST_PATH = "/ib-managers";
const API_IBC_CONFIGS_LIST_PATH = "/ib-instance-configs";
const API_AGN_CONFIGS_LIST_PATH = "/agent-configs";

const API_IB_MANAGER_CONTROL_WS = `${PYX_API_WS_PROTOCOL}:\/\/${PYX_API_HOST}\/ib-managers\/{ib_manager_name}\/control-ws`;
const API_IB_MANAGER_LOGGER_WS = `${PYX_API_WS_PROTOCOL}:\/\/${PYX_API_HOST}\/ib-managers\/{ib_manager_name}\/logger-ws`;
const API_IB_INSTANCE_LOGGER_WS = `${PYX_API_WS_PROTOCOL}:\/\/${PYX_API_HOST}\/ib-instances\/{ib_instance_id}\/logger-ws`;

const API_TRADE_FULL_BROKER_MS_WS = `${PYX_API_WS_PROTOCOL}:\/\/${PYX_API_HOST}\/trade\/full-broker-ms\/{channel_name}`;
const API_TRADE_USER_SPACE_WS = `${PYX_API_WS_PROTOCOL}:\/\/${PYX_API_HOST}\/trade\/user-spaces\/{config_broker_id}`;

const API_USER_IB_INSTANCE_CONFIG_PATH =
  API_USER_LIST_PATH + "/ib-instance-configs";

const API_MY_ACCOUNTS_LIST_PATH = "/my-accounts";
const API_MY_ACCOUNT_TRANSACTIONS_LIST_PATH = `${API_MY_ACCOUNTS_LIST_PATH}/{account_header_uuid}/transactions`;
const API_ACCOUNT_HEADERS_LIST_PATH = "/account-headers";
const API_ACCOUNT_HEADER_TRANSACTIONS_LIST_PATH =
  "/account-header-transactions";
const API_STRIPE_PATH = "/stripe";
const API_STRIPE_MARKET_PRODUCTS_PATH = `${API_STRIPE_PATH}/market-products`;
const API_STRIPE_SUSCRIPTIONS_PATH = `${API_STRIPE_PATH}/subscriptions`;
const API_STRIPE_CHECKOUT_PATH = `${API_STRIPE_PATH}/checkout`;

const API_STRIPE_PORTAL_PATH = `${API_STRIPE_PATH}/redirect-portal-session`;
const API_STRIPE_PAYMENT_INTENT_PATH = `${API_STRIPE_PATH}/payment-intents`;
const API_STRIPE_PURCHASES_PATH = `${API_STRIPE_PATH}/purchases`;

const API_SCHEMA_PROFILES_PATH = "/schemas/profiles";
const API_PROFILES_PATH = "/profiles";
const API_WEBHOOK_NOTIFY_PATH = "/webhook-notify";
const API_NODE_BROKERS_PATH = "/node-brokers";
const API_NOTIFY_LINKING_PATH = "/glue-notify";
const API_PULSES_PATH = "/pulses";
const API_PULSES_PLANS_PATH = `${API_PULSES_PATH}/plans`;
const API_PULSES_COUNTERS_PATH = `${API_PULSES_PATH}/counters`;

const API_TRADOVATE_AUTH_PATH = "/tradovate/oauth";
const API_TRADINGVIEW_ALERTS_PATH = "/tradingview/alert";
const API_TRADINGVIEW_STRATEGIES_PATH = "/tradingview/strategies";

Vue.prototype.T = T;
Vue.prototype.ST = ST;
Vue.prototype.PYX_API_SERVER_HTTP = PYX_API_SERVER_HTTP;
Vue.prototype.pyx_api = axios.create({
  withCredentials: true,
  baseURL: PYX_API_SERVER_HTTP,
});
Vue.prototype.apip = {
  API_TOKEN_PATH: API_TOKEN_PATH,
  API_USER_LIST_PATH: API_USER_LIST_PATH,
  API_NEW_USER_PATH: API_NEW_USER_PATH,
  API_CHALLENGE_PATH: API_CHALLENGE_PATH,
  API_IB_MANAGERS_LIST_PATH: API_IB_MANAGERS_LIST_PATH,
  API_IBC_CONFIGS_LIST_PATH: API_IBC_CONFIGS_LIST_PATH,
  API_AGN_CONFIGS_LIST_PATH: API_AGN_CONFIGS_LIST_PATH,
  API_IB_MANAGER_CONTROL_WS: API_IB_MANAGER_CONTROL_WS,
  API_IB_MANAGER_LOGGER_WS: API_IB_MANAGER_LOGGER_WS,
  API_IB_INSTANCE_LOGGER_WS: API_IB_INSTANCE_LOGGER_WS,
  API_TRADE_FULL_BROKER_MS_WS: API_TRADE_FULL_BROKER_MS_WS,
  API_TRADE_USER_SPACE_WS: API_TRADE_USER_SPACE_WS,
  API_USER_IB_INSTANCE_CONFIG_PATH: API_USER_IB_INSTANCE_CONFIG_PATH,
  API_MY_ACCOUNTS_LIST_PATH: API_MY_ACCOUNTS_LIST_PATH,
  API_MY_ACCOUNT_TRANSACTIONS_LIST_PATH: API_MY_ACCOUNT_TRANSACTIONS_LIST_PATH,
  API_ACCOUNT_HEADERS_LIST_PATH: API_ACCOUNT_HEADERS_LIST_PATH,
  API_ACCOUNT_HEADER_TRANSACTIONS_LIST_PATH:
    API_ACCOUNT_HEADER_TRANSACTIONS_LIST_PATH,
  API_STRIPE_MARKET_PRODUCTS_PATH: API_STRIPE_MARKET_PRODUCTS_PATH,
  API_STRIPE_SUSCRIPTIONS_PATH: API_STRIPE_SUSCRIPTIONS_PATH,
  API_STRIPE_CHECKOUT_PATH: API_STRIPE_CHECKOUT_PATH,
  API_STRIPE_PORTAL_PATH: API_STRIPE_PORTAL_PATH,
  API_STRIPE_PAYMENT_INTENT_PATH: API_STRIPE_PAYMENT_INTENT_PATH,
  API_STRIPE_PURCHASES_PATH: API_STRIPE_PURCHASES_PATH,
  API_SCHEMA_PROFILES_PATH: API_SCHEMA_PROFILES_PATH,
  API_PROFILES_PATH: API_PROFILES_PATH,
  API_WEBHOOK_NOTIFY_PATH: API_WEBHOOK_NOTIFY_PATH,
  API_NODE_BROKERS_PATH: API_NODE_BROKERS_PATH,
  API_NOTIFY_LINKING_PATH: API_NOTIFY_LINKING_PATH,
  API_PULSES_PATH: API_PULSES_PATH,
  API_PULSES_PLANS_PATH: API_PULSES_PLANS_PATH,
  API_PULSES_COUNTERS_PATH: API_PULSES_COUNTERS_PATH,
  API_TRADOVATE_AUTH_PATH: API_TRADOVATE_AUTH_PATH,
  API_TRADINGVIEW_STRATEGIES_PATH: API_TRADINGVIEW_STRATEGIES_PATH,
  API_TRADINGVIEW_ALERTS_PATH: API_TRADINGVIEW_ALERTS_PATH,
};
Vue.prototype.stripe_pk = process.env.VUE_APP_STRIPE_PK;

const app = new Vue({
  store,
  vuetify,
  render: (h) => h(App),
  router,

  data: () => ({
    //
  }),
}).$mount("#app");
