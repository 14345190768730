export default {
  data() {
    return {
      alert_snack_active: false,
      alert_snack_message: "",
      alert_snack_timeout_ms: 20000,
      alert_snack_c_timeout: null,
      alert_snack_color: "error",
    };
  },
  methods: {
    show_alert_snack(message_srt) {
      this.alert_snack_message = message_srt;
      this.alert_snack_active = true;
      if (this.alert_snack_c_timeout) {
        clearTimeout(this.alert_snack_c_timeout);
      }
      this.alert_snack_c_timeout = setTimeout(
        this.close_alert_snack,
        this.alert_snack_timeout_ms
      );
    },
    close_alert_snack() {
      this.alert_snack_active = false;
      if (this.alert_snack_c_timeout) {
        clearTimeout(this.alert_snack_c_timeout);
      }
    },
  },
};
