<template>
  <div>
    <v-snackbar
      v-model="alert_snack_active"
      :timeout="-1"
      :color="alert_snack_color"
    >
      {{ alert_snack_message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="close_alert_snack"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import alertSnackV1 from "@/mixins/alertSnackV1";
import alertErrorSimple from "@/mixins/alertErrorSimple";

export default {
  name: "SystemErrorAlert",
  mixins: [alertErrorSimple, alertSnackV1],
  data() {
    return {
      alert_snack_color: "error",
    };
  },
  methods: {
    show_string_message(message_srt) {
      this.show_alert_snack(message_srt);
    },
    close_error() {
      this.close_alert_snack();
    },
  },
  computed: {
    ...mapState(["system_error", "system_info"]),
  },
  watch: {
    "$store.state.system_error": function (new_val, old_val) {
      this.alert_snack_color = "error";
      this.show_error(new_val);
    },
    system_info: function (new_val) {
      this.alert_snack_color = "info";
      this.show_info(new_val);
    },
  },
};
</script>
