<template>
  <div>
    <SystemErrorAlert></SystemErrorAlert>
    <div v-if="!is_ready"></div>
    <Profile v-else-if="requires_profile_data" :missing_only="true"></Profile>
    <dashboard v-else-if="is_login"></dashboard>
    <OfflineHome v-else-if="is_login == false"></OfflineHome>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SystemErrorAlert from "@/components/SystemErrorAlert.vue";

import Profile from "@/components/profile/Profile.vue";
import Dashboard from "@/components/dashboard/Dashboard.vue";
import OfflineHome from "@/components/home/OfflineHome.vue";

export default {
  name: "HomeView",
  components: { SystemErrorAlert, Dashboard, OfflineHome, Profile },
  data() {
    return {};
  },
  computed: {
    ...mapState(["is_login", "is_ready", "user_data", "requires_profile_data"]),
  },
  watch: {},
  mounted() {
    // console.log(this.PYX_API_SERVER_HTTP);
    // console.log(process.env.VUE_APP_PUBLIC_PATH);
  },
};
</script>
