<template>
  <v-container>
    <v-row
      ><v-col cols="12">
        <v-card width="100%">
          <v-card-title>
            Choose your subscription plan
            <v-spacer></v-spacer>
            <v-btn color="primary" class="text-none" :to="{ name: 'billing' }">
              Buy subscription</v-btn
            >
          </v-card-title>
          <v-card-subtitle
            >Buy subscription to continue using Proteryx</v-card-subtitle
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row
      ><v-col cols="12">
        <v-card width="100%" flat>
          <v-card-title class="d-flex">
            <span class="d-flex justify-start">Trading Opportunities</span>
            <v-spacer></v-spacer>
            <v-btn-toggle
              class="flex-wrap d-flex justify-end"
              v-model="option"
              borderless
              dense
              color="primary accent-3"
              mandatory
            >
              <v-btn class="text-none" value="signals">
                Start Signals Pro (Beta)
              </v-btn>
              <v-btn disabled class="text-none" value="copy"
                >Copy Pro (Coming Soon)</v-btn
              >
              <v-btn disabled class="text-none" value="strategy"
                >Launch Strategies (Coming soon)</v-btn
              >
            </v-btn-toggle>
          </v-card-title>
          <v-card-title v-if="option == 'signals'"
            >Start Signals Pro
          </v-card-title>
          <v-card-text v-if="option == 'signals'">
            <v-row
              ><v-col cols="12" md="6" class="text-justify">
                <p>
                  Signals Pro allows flexible use of TradingView functionality.
                  If you have a TradingView subscription, you can limit alerts
                  or parts of strategies and eventually use them in the bot.
                </p>
                <v-btn color="primary" :to="{ name: 'signalspro' }"
                  >Start</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
export default {
  name: "DashMain",
  components: {},
  data() {
    return {
      option: "signals",
      mock: false,
      debug: false,
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
