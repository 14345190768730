var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"card"}})],1):_c('v-card',[_c('v-card-title',{on:{"click":_vm.log_schema}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[(_vm.schema)?_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-jsf',{attrs:{"schema":_vm.schema},scopedSlots:_vm._u([{key:"custom-phone",fn:function(context){return [_c('vue-tel-input',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"mode":"international","validCharactersOnly":true,"inputOptions":{
                required: true,
                placeholder: context.label,
                showDialCode: true,
                styleClasses: '',
              },"invalidMsg":"Invalid"},on:{"input":(p, obj) => {
                  return _vm.input_phone(p, obj, context);
                },"validate":(p) => {}},scopedSlots:_vm._u([{key:`icon-right`,fn:function(obj){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.valid_phones[context.fullKey] ? 'green' : 'red'}},[_vm._v("mdi-check-circle")])]}}],null,true),model:{value:(context.value),callback:function ($$v) {_vm.$set(context, "value", $$v)},expression:"context.value"}})]}},{key:"custom-phone-lax",fn:function(context){return [_c('vue-tel-input',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"autoFormat":false,"mode":"auto","validCharactersOnly":true,"inputOptions":{
                autocomplete: false,
                required: true,
                placeholder: context.label,
                showDialCode: false,
                styleClasses: '',
              },"invalidMsg":"Invalid"},on:{"input":(p, obj) => {
                  return _vm.input_phone_lax(p, obj, context);
                },"validate":(p) => {
                  return _vm.validate_lax(p, context);
                }},scopedSlots:_vm._u([{key:`icon-right`,fn:function(obj){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.valid_phones[context.fullKey] ? 'green' : 'red'}},[_vm._v("mdi-check-circle")])]}}],null,true),model:{value:(context.value),callback:function ($$v) {_vm.$set(context, "value", $$v)},expression:"context.value"}})]}}],null,false,3201487514),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),(_vm.debug)?_c('p',[_vm._v("valid="+_vm._s(_vm.valid)+" , "+_vm._s(_vm.model))]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hide_save),expression:"!hide_save"}],attrs:{"color":"blue darken-1","text":"","disabled":!_vm.valid},on:{"click":_vm.on_persist}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }